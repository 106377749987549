body
{
	background-color: #1E2019;
	font-family: 'Rajdhani', sans-serif;
}

p
{
    font-size: 110%;    
}

sidebar
{
    font-size: 75%;

}

li
{
    font-size: 110%;
}

h1
{
	color: #BFD7EA;
}

h3
{
    color: #BFD7EA;
}


#header
{
	font-family: 'Amatic SC', cursive;
    background-color: #ebebeb;
    background-image: url("../images/Logo.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
 
    padding-top: 5%;
    padding-bottom: 5%;
    margin-bottom: 0%;
}

.jumbo-image-size-sm
{
    min-height: 200px;
}
.jumbo-image-size-md
{
    min-height: 300px;
}
.jumbo-image-size-lg
{
    min-height: 400px;
}
.jumbo-image-size-xl
{
    min-height: 500px;
}

#md-header-text
{
    font-size: 120%;
}


.container 
{
    max-width: 100em;
}

.card-colour
{
	background-color: #ebebeb;
}

.article-text
{
    background-color: #ebebeb;
    padding: 6%;
    font-size: 120%
}

/* This is the blog snippet's image*/
.blog-snippet-img
{
    display: block;
    width: 342px;
    height: 192px;
    /*max-width: 100%;
    height: auto;*/
}

.video-settings
{
    max-width: 70%;
}

.carousel-caption {
    background: rgba(0, 0, 0, 0.85);
}


.social-icon
{
    font-size: 130%;
}

#mc-embedded-subscribe-form input[type=checkbox]
{
    display: inline; 
    width: auto;
    margin-right: 10px;
}

.center {
  text-align: center;
}

.iframe-container {
  overflow: hidden;
  // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
  padding-top: 56.25%;
  position: relative;
}
 
.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

.presskit-title
{
  font-weight: bold;
  font-size: 150%;
  color: #dd7e6b;
}

.presskit-subtitle
{
  font-weight: bold;
  font-size: 120%;
  color: #dd7e6b;
}

.navbar-custom {
    background-color: #ebebeb;
}

.display-5 {
  font-size: 150%;
}





.modal {
    text-align: center;
}
.modal-dialog {
    text-align: left;
    max-width: 60%;
    width: auto !important;
    display: inline-block;
}
.cursor-select {
  cursor: pointer;
}





